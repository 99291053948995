<template>
  <div>
    <div class="ml-0 pl-0">
      <v-row no-gutters>
        <v-col>
          <Banner type="info" bannerMessage="Commission will be paid to your office via your preferred method. In the event of a cancellation, the refund check will be physically mailed to the insured and the return agency commission will be due from your agency back to Amwins. <br> Premium financed policies are not eligible." />
        </v-col>
      </v-row>
    </div>

    <v-container fluid class="ml-0 pl-0">
      <v-row no-gutters>
        <v-col>
          <div class="btn-tab-trench">
            <div class="btn-tab-wrapper">
              <div class="btn-tab" :class="currentTab === 'payNow' ? 'active' : ''" @click="currentTab = 'payNow'">
                <span class="tab-title">Pay Now</span>
                <div class="tab-description">Insured Bill</div>
              </div>
              <div class="btn-tab" :class="currentTab === 'sendInvoice' ? 'active' : ''" @click="currentTab = 'sendInvoice'">
                <span class="tab-title">Send Invoice</span>
                <div class="tab-description">Insured Bill</div>
              </div>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>

    <div class="ml-0 pl-0" v-if="refinementProcessing">
      <v-row no-gutters>
        <v-col>
          <Banner type="info" bannerMessage="Given the change in effective date, we are confirming your final premium." />
        </v-col>
      </v-row>
    </div>

    <v-card class="py-4 px-4">
      <div class="payment-container">
        <v-icon class="product-icon">{{ icon }}</v-icon>
        <div v-if="!refinementProcessing" class="payment-details">
          <span class="total-title">Total premium, taxes and fees:</span>
          <span class="total-premium">{{ totalPremium }}</span>
          <span class="total-fees" v-if="!paymentComplete && currentTab === 'payNow'">ePay ACH fee: {{ achFee }} | ePay Credit Card Fee: {{ ccFee }}</span>
        </div>
        <div v-if="refinementProcessing" class="payment-details">
          <Loading :size="64" class="ml-lg-16" />
        </div>
        <div class="payment-details payment-details--completed" v-if="paymentComplete && currentTab === 'payNow'">
          <span class="total-title text-center">Payment method:</span>
          <span v-if="achTransactionType" class="text-center">ACH <span class="link" @click="makePayment">Edit</span></span>
          <span v-else class="text-center">Credit Card <span class="link" @click="makePayment">Edit</span></span>
          <span class="total-fees text-center" v-if="achTransactionType">ePay ACH fee: {{ achFee }}</span>
          <span class="total-fees text-center" v-else>ePay Credit Card Fee: {{ ccFee }}</span>
        </div>
        <div class="payment-button" v-if="!paymentComplete && currentTab === 'payNow'">
          <v-btn
            :class="{ 'hasError': paymentError && paymentError.length }"
            color="secondary"
            large
            type="button"
            @click="makePayment"
            :disabled="loading || disabled || refinementProcessing"
            key="save">
            <Loading v-if="loading" :size="24" class="mr-2" />
            Pay Now
          </v-btn>
          <ErrorMessages class="pt-2 text-bold" :errors="paymentError" alignment="center"></ErrorMessages>
        </div>
        <div class="payment-complete" v-if="paymentComplete && currentTab === 'payNow'">
          <div class="check-icon">
            <i class="fas fa-check-circle fa-lg"></i>
          </div>
          Completed
        </div>
      </div>
    </v-card>

    <v-container v-if="currentTab === 'sendInvoice'" fluid class="ml-0 pl-0 mt-4">
      <v-row>
        <v-col>
          <div class="info-wrapper">
            <b>You will receive an invoice from an @amwins.com email address within one business day with options to pay online (using either ACH or credit card via ePayPolicy), through a physically mailed check, or via lockbox.</b>
            <b>The insured will also receive a copy of the invoice.</b>
          </div>
        </v-col>
      </v-row>
    </v-container>

    <v-container v-if="currentTab === 'payNow'" fluid class="ml-0 pl-0 mt-4">
      <v-row>
        <v-col style="font-size: 14px">ePayPolicy operates this payment portal and may impose fees for processing payments via credit card or ACH debit. If a fee is applicable to this payment, it is reflected in the line item "Fee" above. Any such fee will be retained exclusively by ePayPolicy and neither Amwins nor any of its affiliates will receive any portion of, or profit, from any such fee. By clicking "Submit", I hereby (i) agree that my use of this payment portal is subject to ePayPolicy's terms and conditions, and (ii) authorize ePayPolicy to process payment by charging the credit card or debiting the ACH account, as applicable, in accordance with the payment method selected. By clicking "Submit", I authorize payment.</v-col>
      </v-row>
    </v-container>

  </div>
</template>

<script>
import { defineComponent } from 'vue';

import Banner from '@/components/ui/Banner.vue';
import { formatMoney } from '@/scripts/helper';
import { getTokenAsync } from '@/plugins/auth';

export default defineComponent({
  name: 'PaymentV2',
  components: {
    Banner
  },
  props: {
    value: [String, Object],
    errors: Array,
    disabled: Boolean,
    endorsement: Boolean,
    module: String
  },
  emits: [
    'change',
    'validate'
  ],
  data() {
    return {
      newValue: null,
      currentTab: 'payNow',
      paymentInfo: null,
      feeInfo: null,
      paymentComplete: null,
      loading: false,
      paymentError: null,
      validationEnabled: false,
      isValid: false,
      isDirty: true
    }
  },
  watch: {
    currentTab (val) {
      this.updateFormControl(val);
      if (this.currentTab === 'sendInvoice') {
        this.paymentError = null;
      } else if(!this.paymentComplete) {
        this.paymentError = ['Required'];
      }
      this.isValid = this.paymentError === null;
      this.newValue = { ...this.newValue, isValid: this.isValid };
    },
    errors (errors) {
      if (errors && errors.length) {
        this.validateInternal();
      }
    },
    value: {
      immediate: true,
      handler(newVal) {
        this.newValue = newVal;
      }
    },
    newValue () {
      this.emitValue();
    },
    '$store.state.additionalForm.selectedQuote.IsLatestQuote'(val) {
      if (val) {
        this.paymentInfo = null;
        this.feeInfo = null;
        this.newValue = null;
        this.paymentComplete = null;
        this.checkPayment();
      }
    },
    endorsement (val) {
      if (val) {
        this.paymentInfo = null;
        this.feeInfo = null;
        this.newValue = null;
        this.paymentComplete = null;
        this.checkPayment();
      }
    } 
  },
  computed: {
    refinementProcessing() {
      if (this.endorsement) return false;

      return !this.$store.state.additionalForm.selectedQuote.IsLatestQuote;
    },
    totalPremium () {
      if (this.paymentComplete) {
        return formatMoney(this.paymentInfo?.BillableTotal, true);
      } else {
        return formatMoney(this.computedFeeInfo?.TotalPremium, true);
      }
    },
    achTransactionType() {
      return this.newValue.PaymentInfo.TransactionType.toLowerCase() === 'ach'
    },
    computedFeeInfo () {
      return this.feeInfo
    },
    achFee () {
      if (this.paymentComplete) {
        return formatMoney(this.paymentInfo?.PayerFee, true);
      } else {
        return formatMoney(this.computedFeeInfo?.ACHFee, true);
      }
    },
    ccFee () {
      if (this.paymentComplete) {
        return formatMoney(this.paymentInfo?.PayerFee, true);
      } else {
        return formatMoney(this.computedFeeInfo?.CCFee, true);
      }
    },
    icon () {
      switch (this.$store.getters.getControlValue('additionalForm', 'CoverageOption')) {
        case 'flood':
          return 'fak fa-flood';
        case 'dp3':
          return 'fak fa-dwelling';
        case 'ho6':
          return 'fak fa-condo';
        case 'ho4':
          return 'fak fa-renters';
        case 'wind':
          return 'fak fa-wind';
        default:
          return 'fak fa-home';
      }
    }
  },
  async created () {
    this.loadSupplementalData();
    this.updateFormControl(this.currentTab);
    await this.checkPayment();
    this.validationEnabled = true;
  },
  methods: {
    validateInternal () {
      // skip validation if we're not ready to validate...
      // or skip if nothing has changed (dirty flag is false)
      if (!this.validationEnabled || !this.isDirty) return;

      // reset
      this.paymentError = null;

      // validate payment
      if (this.currentTab === 'payNow' && !this.paymentComplete) {
        this.paymentError = ['Required'];
      }

      this.isValid = this.paymentError === null;
      this.isDirty = false;
      this.newValue = { ...this.newValue, isValid: this.isValid };
    },
    emitValue () {
      this.$emit('change', this.newValue);
    },
    loadSupplementalData () {
      var paymentTabData = this.$store.getters.getControlValue(this.module, 'PaymentTab');
      this.currentTab = paymentTabData ?? 'payNow';
    },
    updateFormControl (val) {
      this.$store.commit('updateFormControl', {
        module: this.module,
        name: 'PaymentTab',
        value: val
      });
    },
    async checkPayment () {
      let requestUrl = ''
      if (this.endorsement) {
        requestUrl = this.createVersionedRoute('/api/Epay/endorsement') // endorsement loads versioned contracts
      } else {
        requestUrl = '/api/Epay?' // the epay get has no versioned contracts
      }
      let submissionId = this.endorsement ? this.$route.query.accountId :this.$route.query.id;
      const params = new URLSearchParams();
      params.append('submissionId', submissionId);
      const options = {
        headers: {
          "Authorization": "Bearer " + await getTokenAsync(),
        },
        handleError: false
      };
      const response = await this.$http.get(requestUrl + params.toString(), options).then(response => {
        this.handlePaymentCheckPresent(response.data);
      }).catch(error => {
        this.handlePopulateFees();
      });
    },
    handlePaymentCheckPresent (data) {
      this.paymentComplete = true;
      this.loading = false;
      this.paymentInfo = data;
      this.newValue = {
        isValid: this.isValid,
        PaymentComplete: true,
        PaymentInfo: this.paymentInfo,
        DateTime: new Date()
      }
      this.isDirty = true;
      this.validateInternal();
    },
    createVersionedRoute (route) {
      if (this.$attrs.salesChannel === 'BindV2')
        return `${route}/v2?`;
      else
        return `${route}?`;
    },
    async handlePopulateFees () {
      this.loading = true
      const requestURL = this.createVersionedRoute(this.endorsement? '/api/Epay/feeInfo/Endorsement' : '/api/Epay/feeInfo');
      let submissionId = this.endorsement? this.$route.query.accountId : this.$route.query.id;
      const params = new URLSearchParams();
      params.append('submissionId', submissionId);
      const options = {
        headers: {
          "Authorization": "Bearer " + await getTokenAsync(),
        },
        handleError: false
      };
      const response = await this.$http.get(requestURL + params.toString(), options).then(response => {
        this.feeInfo = response.data;
        this.loading = false;
      }).catch(error => {
        setTimeout(() => {
          this.handlePopulateFees();
        }, 2000);
      });
    },
    async makePayment () {
      this.loading = true;
      const requestURL = this.createVersionedRoute(this.endorsement ? '/api/Epay/url/endorsement' : '/api/Epay/url');
      const params = new URLSearchParams();
      params.append('submissionId',  this.endorsement? this.$route.query.accountId : this.$route.query.id);
      params.append('url', window.location.origin + '/epay' + (this.$attrs.salesChannel === 'BindV2' ? '/v2' : '/v1'));
      const options = {
        headers: {
          "Authorization": "Bearer " + await getTokenAsync(),
        },
        handleError: false
      }

      const response = await this.$http.get(requestURL + params.toString(), options);
      let height = window.innerHeight;
      let width = window.innerWidth;

      let top = Math.max((height - 1000) / 2, 0);
      let left = Math.max((width - 1000) / 2, 0);

      let windowHeight = Math.min(height, 1000);
      let windowWidth = Math.min(width, 1000);

      //The payment window is stored in a variable called 'w' so the method close() can be called upon it later
      let w = window.open(
        response.data,
        'payment',
        "menubar=1,location=no,resizable=1,width=" + windowWidth + ",height=" + windowHeight + ",top=" + top + ",left=" + left
      );

      w.focus();
      var wInterval = setInterval(() => {
        if (w.closed) {
          this.loading = true;
          clearInterval(wInterval);
          this.checkPayment();
          this.loading = false;
          return;
        }
      }, 1000);
    }
  }
});
</script>

<style scoped lang="scss">
@import '@/style.scss';

// NOTE: this css is in the design library, and should be included from there when possible (and removed here)
.btn-tab-trench {
  border-bottom: 1px solid $gray4;
  background-color: white;
  margin-left: -32px;
  padding-left: 32px;

  .btn-tab-wrapper {
    display: flex;
    flex-wrap: wrap;
  }

  .btn-tab {
    color: $gray3;
    cursor: pointer;
    padding-left: 20px;
    padding-right: 20px;    
    padding-top: 9px;
    padding-bottom:5px;
    color: $amwins-blue;
    background-color: #F6F6F6;
    flex: 1 1 50%;
    max-width: 200px;
    border: 1px solid $gray4;
    border-bottom: none;
    border-left: none;
    
    &:first-of-type {
      border-left: 1px solid $gray4;
    }
    &.active {
      --progress: 100%;
      background-color: #FBFDFF;
      padding-top:5px;
      border-top: 4px solid $amwins-blue;
      box-shadow: 0 10px 0 0px #FBFDFF;
    }

    .tab-title {
      font-weight: 500;
    }
    .tab-description {
      font-size: 12px;
      color: $gray3;
      font-weight: 400;
    }
  }
}

.payment-container {
  display: flex;

  div:last-of-type:not(:nth-child(2)) {
    margin-left: auto;
  }

  .product-icon {
    font-size: 100px;
    color: $amwins-blue;
  }

  .payment-details {
    display: flex;
    flex-direction: column;
    align-self: center;

    &--completed {
      margin-top: 8px;
      margin-left: auto;
    }

    .total-title {
      font-size: 14px;
      font-weight: bold;
    }
    .total-premium {
      font-size: 24px;
      font-weight: bold;
    }
    .total-fees {
      font-size: 12px;
    }
  }

  .payment-button {
    align-self: center;
  }

  .payment-complete {
    align-self: center;
    color: $tertiary-green;
    display: flex;
  }
}

.check-icon {
  margin-right: 8px;
  * {
    color: $tertiary-green;
  }
}

.info-wrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.link {
  cursor: pointer;
  color: $amwins-blue;
  text-decoration: underline;
}
</style>